@keyframes shake-play {
  0%,
  100% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(-10deg) scale(1.15);
  }
  40% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes slide-to-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes slide-to-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animation_cell_winner {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }
  25% {
    transform: scale(1.2) rotate(10deg);
    filter: brightness(1.3);
  }
  50% {
    transform: scale(1.1) rotate(-10deg);
    filter: brightness(1.5);
  }
  75% {
    transform: scale(1.2) rotate(10deg);
    filter: brightness(1.3);
  }
  100% {
    transform: scale(1) rotate(0);
    filter: brightness(1);
  }
}
