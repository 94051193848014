* {
  //
  // Layout dimensions
  //
  --centered-w: 1024px;
  --header-h: 85px;
  --circle-icon-w: 42px;
  --padding-centered-w: 0px;
  --lateral-left-width: 320px;
  --lateral-right-width: 400px;
  //
  // Game chip dimensions
  //
  --game-stage-ratio: 0.6;
  --game-ratio: 0.65625;
  --game-w: 220px;
  --game-h: calc(var(--game-w) * var(--game-ratio));
  --game-wrapper-h: calc(var(--game-h) + 44px);
  --game-gap: 0.65em;
  //
  // Elements dimensions
  //
  --border-radius-small: 8px;
}
@media (max-width: 1024px) {
  * {
    --centered-w: calc(var(--display-w) - 2 * var(--padding-centered-w));
    --padding-centered-w: 1em;
  }
}
@media (max-width: 600px) {
  * {
    --lateral-right-width: var(--display-w);
    --padding-centered-w: 0.65em;
  }
}
@media (max-width: 540px) {
  * {
    --game-w: 190px;
    --game-gap: 0.45em;
    --padding-centered-w: 0.5em;
  }
}
@media (max-width: 400px) {
  * {
    --game-w: 170px;
    --game-gap: 0.45em;
    --padding-centered-w: 0.5em;
  }
}
