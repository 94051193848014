:root {
  --primary-color-001: #88fc7e;
  --text-color-001: #e3fee2;
  --background-color-001: #021700;
  --background-light-color-001: #053d00;
  --accent-color-001: #fb2677;
  --border-color-001: #4c524c;
}

body {
  background-color: var(--background-color-001);
  color: var(--text-color-001);
}

body p,
a,
h1,
h2,
h3,
li {
  color: var(--text-color-001);
}

.lotodobicho {
  display: grid;
  grid-template-rows: auto auto 1fr;
  min-height: 100vh;
  background-color: var(--background-color-001);
  padding: 2rem;
}

/* Header styles */
.header-001 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: grid;
  grid-template-columns: auto 120px 120px;
  padding: 1rem;
  gap: 1em;
  background-color: var(--background-light-color-001);
}
.logo img {
  height: 42px;
}

/* Navigation styles */
.topnav {
  position: fixed;
  top: 75px;
  right: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  padding: 1rem;
  background-color: #222222;
}

.topnav a {
  border: 1px solid var(--text-color-001);
  border-radius: 5px;
  color: var(--text-color-001);
  text-decoration: none;
  text-align: center;
  padding: 0.5rem;
  transition: background-color 0.3s ease;
}

.topnav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--primary-color-001);
}
.content-wrapper {
  padding-top: 170px;
  max-width: 720px;
  margin: 0 auto;
  width: 100%;
}
section {
  margin-top: 2em;
}
._title {
  color: var(--primary-color-001);
  font-size: 1.5em;
  font-weight: 800;
}
.table {
  color: var(--text-color-001);
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  border: 1px solid var(--border-color-001);
  padding: 0.5rem;
  text-align: left;
}

.table th {
  background-color: rgba(136, 252, 126, 0.1);
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: rgba(136, 252, 126, 0.05);
}
.image-iphone {
  display: grid;
  justify-content: center;
  padding: 2rem;
}
.image-pix {
  display: grid;
  justify-content: center;
  padding: 2rem;
}

.image-iphone img {
  width: 300px;
}
.image-pix img {
  height: 100px;
}
.payments {
  display: grid;
  justify-content: center;
  padding: 2rem;
  grid-gap: 1em;
  grid-template-columns: repeat(3, 120px);
}
.payments img {
  height: 100px;
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  overflow: hidden;
}
.back-ground-image {
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}

.presentation {
  display: grid;
  justify-content: center;
}

.highlight-001 {
  justify-self: center;
  font-size: 2.5rem;
  color: var(--primary-color-001);
  margin-bottom: 0.5rem;
  font-weight: 1000;
  text-align: center;
}

.desc-001 {
  justify-self: center;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: var(--text-color-001);
  font-weight: 600;
}
.desc-desc {
  padding: 1em 0;
  justify-self: center;
  font-size: 1.2rem;
  color: var(--text-color-001);
}
.header-2 {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: 1rem;
  z-index: 1;
}

.logo-compuesto img {
  max-width: 200px;
}

.enter-btn,
.register-btn {
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.enter-btn {
  background-color: var(--primary-color-001);
  color: var(--background-color-001);
}

.enter-btn:hover {
  background-color: var(--accent-color-001);
  color: var(--text-color-001);
}

.register-btn {
  background-color: transparent;
  color: var(--primary-color-001);
  border: 2px solid var(--primary-color-001);
}

.register-btn:hover {
  background-color: var(--primary-color-001);
  color: var(--background-color-001);
}

/* Section styles */
.down-pres {
  padding: 2rem;
  background-color: var(--background-light-color-001);
}

.txt-001 {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  color: var(--text-color-001);
}

.go-to-start {
  display: block;
  width: 50px;
  height: 50px;
  margin: 2rem auto;
}

#plus-circle-icon {
  width: 100%;
  height: 100%;
  fill: var(--primary-color-001);
  transition: fill 0.3s ease;
}

#plus-circle-icon:hover {
  fill: var(--accent-color-001);
}
.apk-wrapper {
  display: grid;
  justify-content: center;
  padding: 2rem;
}
.apk-wrapper img {
  height: 72px;
}
.why-register {
  grid-gap: 1em;
  display: grid;
  grid-gap: 1em;
  grid-template-rows: min-content min-content min-content;
  padding: 1em;
}
.why-register .label-title {
  font-size: 32px;
  font-weight: 1000;
  justify-self: center;
  color: var(--primary-color-001);
  margin: 0;
}
.why-register .columns {
  grid-gap: 1em;
  display: grid;
}
.why-register .columns .col {
  grid-row: 1;
  grid-gap: 0;
  background-color: var(--background-light-color-001);
  border-radius: 8px;
  display: grid;
  padding: 1em;
  place-content: center;
}
.why-register .columns .col .up-number {
  color: var(--primary-color-001);
  font-size: 20px;
  font-weight: 1000;
  margin: 0;
  text-align: center;
}
.why-register .columns .col .down-txt {
  color: var(--text-color-001);
  font-size: clamp(12px, 3vw, 36px);
  margin: 0;
  text-align: center;
}
.why-register .button {
  display: grid;
  justify-content: center;
}
footer,
footer .logos {
  background-color: var(--black-chinese);
  /* width: 100%; */
}
footer .logos {
  grid-gap: 2em;
  align-content: center;
  display: grid;
  justify-content: center;
  padding: 2em;
}
footer .logos .wrapper {
  align-self: center;
  grid-row: 1;
}
.plus18 img {
  height: 128px;
}
footer .logos .wrapper picture,
footer .logos .wrapper picture img,
footer .logos .wrapper.itech picture,
footer .logos .wrapper.itech picture img {
  height: 128px;
}
footer .links {
  grid-gap: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
  justify-content: center;
  padding: 24px;
  width: 100%;
}
footer .links .box ._title {
  color: var(--primary-color-001);
  display: block;
  font-size: 1.4em;
  height: 35px;
  line-height: 20px;
  text-decoration: none;
}
footer .links .box .list {
  display: grid;
  justify-self: center;
}
footer .links .box .list a {
  color: var(--text-color-001);
  line-height: 24px;
}
footer .links .box .list a:hover {
  color: var(--text-color-001);
}
footer .links .box .list a:active {
  color: var(--primary-color-001);
}
footer .links .box .list a.selected {
  color: var(--text-color-001);
}
footer .langs {
  display: grid;
  place-content: center;
}
footer .langs .band {
  grid-gap: 1em;
  align-content: center;
  border-radius: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 80px));
  height: 60px;
  justify-content: center;
  margin: 0 auto;
  padding: 0 2em;
}
footer .langs .band a {
  color: var(--text-color-001);
  grid-row: 1;
  line-height: 24px;
  text-align: center;
  width: 80px;
}
footer .langs .band a:hover {
  color: var(--primary-color-001);
}
footer .langs .band a:active {
  color: var(--text-color-001);
}
footer .langs .band a.selected {
  color: var(--primary-color-001);
  font-weight: 800;
}
footer .company {
  color: var(--text-color-001);
  font-weight: 400;
  padding: 1em;
  text-align: center;
}
footer .company .higlight {
  color: var(--text-color-001);
}
footer .copyright {
  align-content: center;
  display: grid;
  justify-content: center;
  padding: 1em;
}
footer .copyright .txt {
  color: var(--text-color-001);
  font-size: 1.1em;
  font-weight: 400;
  text-align: center;
}
footer .copyright .txt .higlight {
  color: var(--primary-color-001);
}
@media (max-width: 550px) {
  footer .logos {
    grid-gap: 1em;
    padding: 1em;
  }
  footer .logos .wrapper picture,
  footer .logos .wrapper picture img,
  footer .logos .wrapper.itech picture,
  footer .logos .wrapper.itech picture img {
    height: 72px;
  }
  footer .logos .wrapper.curacao,
  footer .logos .wrapper.xcm {
    width: 72px;
  }
  footer .links {
    grid-template-columns: repeat(auto-fit, minmax(180px, 180px));
    padding: 1em;
  }
  footer .links .box .title {
    height: 55px;
  }
  footer .copyright {
    padding-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .why-register {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .topnav {
    grid-template-columns: 1fr;
  }

  .header-2 {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
