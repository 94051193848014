@import '_constants_';
@import '_colors_';
@import '_animations_';
@import '_fonts_';

* {
  box-sizing: border-box;
}
body,
html {
  display: block;
  margin: 0;
  padding: 0;
  font: 400 14px 'Nunito Sans';
  font-family: 'Nunito Sans', sans-serif;
  height: 100%;
  background-color: var(--background-color);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
textarea {
  margin: 0 !important;
  resize: none;
}
:focus {
  outline: 0;
}

::-webkit-scrollbar {
  display: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::view-transition-old(root) {
  animation:
    600ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    900ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-right;
}

::view-transition-new(root) {
  animation:
    610ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    900ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-left;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input::placeholder {
  color: rgb(89, 128, 89);
}
.dark-backdrop {
  background-color: #2a2a2ad4;
}
