* {
  --border-color: #4c524c;

  --mov-bet-color: #004d00;
  --mov-deposit-color: #057461;
  --mov-withdraw-color: #5a0569;
  --mov-draw-user-bets-color: #5c460e;
  --mov-bonus-color: #001100;
  --mov-game-color: #6c1e04;
  --mov-sports-bet-color: #1533aa;
  --mov-player-reward-color: #000800;
  --mov-movs-stored-color: #28a328;
  --mov-exchange-color: #000400;
  --mov-rpp-converted-color: #28a328;
  --mov-lucky-cells-color: #494fbe;
  --theme: #000;
  --background-color: #021700;
  --background-light-color: #053d00;
  --primary-color: #88fc7e;
  --primary-color-2: #1c1c1c;
  --primary-color-3: #2a2a2a;
  --secondary-color: #6b039a;
  --accent-color: #fb2677;
  --text-color: #e3fee2;
  --text-secondary-color: #021700;
  --text-accent-color: ff5733#000;
  --telegram-color: #38ade1;
}
